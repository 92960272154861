
.survey-card::before{
    display: none;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .survey-details{
        max-width: 846px;
    }
}
@media (min-width:1200px){
}

