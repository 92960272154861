
.addiction-rows {
    .addiction-row:not(:last-child) {
        margin-bottom: 10px;
    }
}
@media (min-width: 1024px) {
    .v-progress-linear--primary-group > .v-progress-linear--primary-wrap:not(:last-child) {
        margin-bottom: 6px;
    }
    .addiction-rows {
        .addiction-row:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
