
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.mypage-navigation-wrap {
    width: 100%;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .mypage-navigation-wrap {
        width: 250px;
    }
}

.mypage-navigation {
    list-style: none;
    .on {
        position: relative;
        color: var(--v-primary-base);
        background-color: #fafafa;
    }
    &-container {
        width: 100%;
    }
    &__list:not(:last-child) {
        margin-bottom: 10px;
    }
    &__link {
        border-radius: 6px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 8px 10px;
        font-size: 1.6rem;
        color: #999;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        i {
            margin-right: 10px;
        }
    }
    &__link[data-toggle] {
        user-select: none;
    }
    &__link[data-toggle] .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 11px;
        height: 20px;
    }
    &__link[data-toggle] .arrow::v-deep svg {
        display: block;
        width: 11px;
        height: 7px;
        pointer-events: none;
    }
    &__link[data-toggle="opened"] {
        border-bottom-style: dashed;
    }
    &__link[data-toggle="opened"] .arrow {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
    }
    &__link[data-toggle="closed"] + &__sub {
        max-height: 0;
    }
    &__sub {
        overflow: hidden;
        transition: all ease 0.2s;
    }
    &__level {
        list-style: none;
        &--1 {
            padding: 15px 0 5px;
            border-bottom: 1px solid #dddddd;
        }
        &-link {
            border-radius: 0;
            display: block;
            padding: 0 10px;
            height: 30px;
            line-height: 1;
            font-size: 18px;
            color: #555555;
            font-weight: 300;
            cursor: pointer;
            user-select: none;
        }
        &-link[data-toggle="opened"] {
            color: var(--v-primary-base);
            font-weight: 700;
        }
        &-link[data-toggle="opened"] + .mypage-navigation__level {
            display: block;
        }
    }
}
@media (min-width: 1200px) {
    .mypage-navigation__link:hover {
        background-color: #fafafa;
    }
}
@media (max-width: 1199px) {
    .mypage-navigation {
        display: flex;
        scrollbar-width: none;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
        white-space: nowrap;
        border-bottom: 1px solid var(--border-color);
        .on {
            border-color: var(--v-primary-base);
            background-color: transparent;
        }
        .on::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: var(--v-primary-base);
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &__list {
            flex: 1 1 auto;
        }
        &__list:not(:last-child) {
            margin-bottom: 0;
        }
        &__link {
            width: auto;
            display: block;
            font-size: 1.4rem;
            text-align: center;
            padding: 12px;
            i {
                display: none;
            }
        }
        &__list-link {
            margin-right: 18px;
            padding: 18px 0 0;
            height: 50px;
            font-size: 14px;
            color: #777777;
            font-weight: 400;
            border-bottom: none;
        }
        &__list-link span {
            display: block;
            padding-bottom: 8px;
            border-bottom: 1px solid transparent;
        }
        &__list-link[data-toggle] .arrow {
            display: none;
        }
        &__list-link[data-toggle="opened"] {
            border-bottom: none;
            color: #333333;
            font-weight: 500;
        }
        &__list-link[data-toggle="opened"] span {
            border-bottom-color: #333333;
        }
        &__level {
            &--1 {
                display: none;
                align-items: center;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50px;
            }
            &--1.active {
                display: flex;
            }
            &-link {
                margin-right: 18px;
                padding: 0;
                font-size: 14px;
            }
        }
    }
    .mypage-navigation__tit {
        font-size: 2.4rem;
        margin-bottom: 0;
    }
}
