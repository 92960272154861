
.total-score{
    min-width: 42px;
}
.link--icon{
    display: block;
    border-radius: 50%;
    overflow: hidden;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .total-score{
        min-width: 53px;
    }
}
@media (min-width:1200px){
}

