:root{
    --container: 1600px;
    --container-sm: 1440px;

    --border-color: #ddd;
    --border-color-dark: #383838;

    --shadow: rgba(0,0,0,.15);

    --font-notosans: "NotoSansKR","Pretendard","Nanum Gothic","Apple SD Gothic Neo",Helvetica,Arial,sans-serif;

	--input-height: 40px;
    --input-small-height: 36px;
	--input-large-height: 48px;
    --input-border-radius: 6px;
    --input-border-color: #ddd;
    --input-border-hover: #111;
    --input-border-focus: var(--v-primary-base);

    --primary-gra: 120deg, var(--v-primary-base), var(--v-secondary-base);
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
	:root{
		--input-height: 48px;
        --input-small-height: 40px;
	    --input-large-height: 58px;
	}
}
@media (min-width:1200px){
}