
.addiction-question-view__head{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .v-chip{
        margin-bottom: 12px;
    }
}
.addiction-question-view__questions{
    >li:not(:last-child){
        margin-bottom: 4px;
    }
}

.addiction-question-view__question-btn.v-btn::before{
    display: none;
}
.addiction-question-view__question-btn:not(:hover).v-btn{
    border-color: #ddd !important;
    color: #666 !important;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .addiction-question-view__head{
        position: relative;
        flex-direction: row;
        width: 100%;
        min-height: 48px;
        padding: 0 150px;
        .v-chip{
            margin-bottom: 0;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .addiction-question-view__questions{
        >li:not(:last-child){
            margin-bottom: 8px;
        }
    }
}
@media (min-width:1200px){
}

